
// MARK: Imports

import React from 'react'
import { Drawer, Radio, Layout, Space, Modal, message } from 'antd'
import { HistoryOutlined, EnvironmentOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import Amplitude from '../../../../utilities/Amplitude'
import { CompanyContext } from '../../../../utilities/Contexts'
import Networking from './../../../../utilities/Networking'
import SafeArea from './../../../../utilities/SafeArea'

import Historical from './components/Historical'
import Live from './components/Live'

// MARK: Component

class MapDrawer extends React.Component {
  static contextType = CompanyContext
  liveRef = React.createRef()
  historicalRef = React.createRef()

  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      drivers: undefined,
      isShowingError: false
    }
  }

  // MARK: Lifecycle

  componentDidMount() {
    this.fetchDrivers()
    this.refresher = setInterval(this.fetchDrivers, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.refresher)
  }

  // MARK: Helpers

  relativeRoot = () => {
    const { match, location } = this.props
    const relativePath = location.pathname.replace(match.path, '')
    const relativeRoot = `/${relativePath.split('/')[1]}`
    return relativeRoot
  }

  // MARK: Fetch

  fetchDrivers = async () => {
    if (this.state.isShowingError) { return }
    try {
      // Refresh the drivers.
      const drivers = await Networking.getDriversInfo()
      this.setState({drivers}, async () => {
        // If the live tab is mounted refresh the locations.
        if (this.liveRef.current) {
          this.liveRef.current.fetchLocations()
        }
      })
    } catch (error) {
      if (this.state.drivers === undefined) {
        Amplitude.trackError(error.message, 'DASHBOARD DRAWER DRIVERS FETCH', true)
        this.setState({isShowingError: true}, () => {
          Modal.confirm({
            closable: false,
            centered: true,
            title: 'Oops!',
            icon: <CloseCircleOutlined style={{color: '#FF4D4F'}}/>,
            content: 'Unable to retrieve a list of company drivers. Are you connected to the internet? If this issue persists, please contact support.',
            onOk: () =>  this.setState({isShowingError: false}, this.fetchDrivers),
            okText: 'Try Again',
            onCancel: Networking.logout,
            cancelText: 'Logout',
            cancelButtonProps: {danger: true}
          })
        })
      } else {
        message.error('Failed to refresh drivers.')
      }
    }
  }

  // MARK: Navigate

  onRadioGroupChange = (event) => {
    this.props.history.replace(this.props.match.path + event.target.value)
  }

  // MARK: Render

  renderRadioButtons = (relativeRoot) => {
    return (
      <Radio.Group
        value={relativeRoot} 
        buttonStyle='solid' 
        className='map-drawer-segment-group'
        onChange={this.onRadioGroupChange}
      >
        <Radio.Button value='/live' className='map-drawer-segment-item'>
          <Space><EnvironmentOutlined />Live</Space>
        </Radio.Button>
        <Radio.Button disabled value='/historical' className='map-drawer-segment-item'>
          <Space><HistoryOutlined />Historical</Space>
        </Radio.Button>
      </Radio.Group>
    )
  }

  renderRoutes = () => {
    const { match } = this.props

    return ( 
      <Switch>
        <Route path={`${match.path}/live`}>
          <Live ref={this.liveRef}
            drivers={this.state.drivers}
            updateMapFocus={this.props.updateMapFocus}
            updateMapDrivers={this.props.updateMapDrivers}
          />
        </Route>
        <Route path={`${match.path}/historical`}>
          <Historical 
            ref={this.historicalRef} 
            drivers={this.state.drivers}
          />
        </Route>
        <Redirect to={`${match.path}/live`}/>
      </Switch>
    )
  }
  
  render = () => {
    return (
      <Drawer
        id='map-drawer'
        placement='left'
        closable={false}
        mask={false}
        maskClosable={false}
        getContainer={false}
        visible={this.props.visible}
        width={this.props.width}
        forceRender={true}
        bodyStyle={{padding: 0, height: '100%'}}
      >
        <Layout id='map-drawer-layout'>
          <Layout.Header id='map-drawer-header' style={{paddingLeft: SafeArea.left(24)}}> 
            {this.renderRadioButtons(this.relativeRoot())}
          </Layout.Header>
          <Layout.Content id='map-drawer-content'>
            {this.renderRoutes()}
          </Layout.Content>
        </Layout>
      </Drawer>
    )
  }
}

export default withRouter(MapDrawer)