
// MARK: Imports

import React from 'react'
import { withRouter } from 'react-router-dom';
import { Layout, Spin, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import Amplitude from '../../utilities/Amplitude'
import { CompanyContext, UserPreferencesContext } from '../../utilities/Contexts'
import Networking from '../../utilities/Networking'
import StoredPreferences from '../../utilities/StoredPreferences'

import Header from './components/Header'
import Map from './components/Map'
import MapDrawer from './components/MapDrawer'

import Logo from '../../assets/logo.png'
import Constants from '../../constants'

// MARK: Constants

const DRAWER_STANDARD_WIDTH = 360
const DRAWER_WIDE_WIDTH = 400
const HIDE_DRAWER_ON_FOCUS_THRESHHOLD = 0.5

// MARK: Component

class Dashboard extends React.Component {
  mapRef = React.createRef()

  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      drawerWidth: DRAWER_STANDARD_WIDTH,
      drawerVisible: true,
      companyInfo: null,
      preferences: StoredPreferences.preferences()
    }
  }

  // MARK: Lifecycle

  componentDidMount() {
    Amplitude.trackPageView('DASHBOARD')
    this.getCompanyInfo()
    this.windowDidResize()
    window.addEventListener('resize', this.windowDidResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.windowDidResize)
  }

  // MARK: Window Resize

  windowDidResize = () => {
    let state = { height: window.innerHeight }
    if (window.innerWidth < Constants.breakpoints.compact) {
      state.drawerWidth = window.innerWidth
    } else if (window.innerWidth < Constants.breakpoints.standard) {
      state.drawerWidth = DRAWER_STANDARD_WIDTH
    } else {
      state.drawerWidth = DRAWER_WIDE_WIDTH
    }

    this.setState(state)
  }

  // MARK: Company Info

  getCompanyInfo = async () => {
    try {
      const companyInfo = await Networking.getCompanyInfo()
      this.setState({companyInfo})
    } catch (error) {
      Amplitude.trackError(error.message, 'DASHBOARD COMPANY FETCH', true)
      Modal.confirm({
        closable: false,
        centered: true,
        title: 'Oops!',
        icon: <CloseCircleOutlined style={{color: '#FF4D4F'}}/>,
        content: 'Unable to retrieve critical company information. Are you connected to the internet? If this issue persists, please try logging in again. If unsuccessful, please contact support.',
        onOk: this.getCompanyInfo,
        okText: 'Try Again',
        onCancel: Networking.logout,
        cancelText: 'Logout',
        cancelButtonProps: {danger: true}
      })
    }
  }

  // MARK: Toggle Drawers

  toggleDrawer = () => {
    const drawerVisible = !this.state.drawerVisible
    this.setState({drawerVisible}, () => {
      Amplitude.trackDrawerToggled(drawerVisible)
    })
  }

  // MARK: Update Map

  updateMapDrivers = (drivers, focusMode = null) => {
    this.mapRef.current.updateDrivers(drivers, focusMode)
  }

  updateMapFocus = (focusMode) => {
    this.mapRef.current.updateFocus(focusMode)
    if (this.state.drawerWidth / window.innerWidth > HIDE_DRAWER_ON_FOCUS_THRESHHOLD) {
      this.toggleDrawer()
    }
  }

  // MARK: User Preferences

  updateLiveDriverPopupStyle = (liveDriverPopupStyle) => {
    StoredPreferences.updateLiveDriverPopupStyle(liveDriverPopupStyle)
    this.setState({preferences: StoredPreferences.preferences()})
  }

  updateLiveDriverListStyle = (liveDriverListStyle) => {
    StoredPreferences.updateLiveDriverListStyle(liveDriverListStyle)
    this.setState({preferences: StoredPreferences.preferences()})
  }

  updateLiveForceDriverPopups = (liveForceDriverPopups) => {
    StoredPreferences.updateLiveForceDriverPopups(liveForceDriverPopups)
    this.setState({preferences: StoredPreferences.preferences()})
  }

  updateLiveFavoriteDrivers = (liveFavoriteDrivers) => {
    StoredPreferences.updateLiveFavoriteDrivers(liveFavoriteDrivers)
    this.setState({preferences: StoredPreferences.preferences()})
  }

  updateLiveOnlyShowFavorites = (liveOnlyShowFavorites) => {
    StoredPreferences.updateLiveOnlyShowFavorites(liveOnlyShowFavorites)
    this.setState({preferences: StoredPreferences.preferences()})
  }

  // MARK: Render

  render = () => {
    if (this.state.companyInfo === null) {
      return (
        <div id='loading-container' style={{height: this.state.height}}>
          <img alt='SwiftFleet Logo' src={Logo} id='loading-image' />
          <Spin size='large' />
        </div>
      )
    } else {
      return (
        <UserPreferencesContext.Provider
          value = {{
            preferences: this.state.preferences,
            updateLiveDriverPopupStyle: this.updateLiveDriverPopupStyle,
            updateLiveDriverListStyle: this.updateLiveDriverListStyle,
            updateLiveForceDriverPopups: this.updateLiveForceDriverPopups,
            updateLiveFavoriteDrivers: this.updateLiveFavoriteDrivers,
            updateLiveOnlyShowFavorites: this.updateLiveOnlyShowFavorites
          }}
        >
          <CompanyContext.Provider value={this.state.companyInfo}>
            <Layout style={{height: this.state.height}}>
              <Header toggleDrawer={this.toggleDrawer} drawerVisible={this.state.drawerVisible} renderMode={this.props.renderMode} />
              <Layout style={{position: 'relative'}}>
                <MapDrawer 
                  width={this.state.drawerWidth}
                  visible={this.state.drawerVisible} 
                  updateMapFocus={this.updateMapFocus}
                  updateMapDrivers={this.updateMapDrivers}
                />
                <Map 
                  ref={this.mapRef} 
                  drawerVisible={this.state.drawerVisible} 
                  drawerWidth={this.state.drawerWidth} 
                  liveForceDriverPopups={this.state.preferences.liveForceDriverPopups}
                  liveDriverPopupStyle={this.state.preferences.liveDriverPopupStyle}
                />
              </Layout>
            </Layout>
          </CompanyContext.Provider>
        </UserPreferencesContext.Provider>
      )
    }
  }
}

export default withRouter(Dashboard)