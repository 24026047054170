
// MARK: Imports

import React from 'react'
import moment from 'moment'
import distance from '@turf/distance'
import { Select, Tag, Timeline } from 'antd'
import { Redirect } from 'react-router-dom'

import Amplitude from '../../../../../utilities/Amplitude'
import Networking from '../../../../../utilities/Networking'

// MARK: Component

export default class Historical extends React.Component {

  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      selectedDriver: null,
      selectedDate: null,
      loadingLocationData: false,
      locationData: null,
    }
  }

  // MARK: Lifecycle

  componentDidMount = () => {
    Amplitude.trackPageView('DASHBOARD DRAWER HISTORICAL')
  }

  componentWillUnmount = () => {}

  // MARK: Fetch Location Date

  fetchLocationData = async () => {
    const { selectedDates, selectedDriver } = this.state
    if (selectedDates == null || selectedDriver == null) { 
      this.setState({locationData: null})
      return 
    }

    this.setState({loadingLocationData: true})
    const startDate = selectedDates[0].format('YYYYMMDD')
    const endDate = selectedDates[1].format('YYYYMMDD')
    try {
      var locations = await Networking.getHistoricalLocations([selectedDriver.driverid], startDate, '00:01', endDate,'24:00')
    } catch (error) {
      // TODO: Handle Errors
    }
    
    // If the API doesn't contain a key for the driver, there was no location data.
    if (!locations.hasOwnProperty(selectedDriver.driverid)) {
      this.setState({locationData: []})
      return
    }
    const driverLocations = locations[selectedDriver.driverid]

    // Filter locations to remove locations that are close to one another in time.
    let reducedLocations = []
    for (let index = 0; index < driverLocations.length; index++) {
      const currentLocation = driverLocations[index]
      const currentDate = moment(`${currentLocation.date} ${currentLocation.time}`)
      if (reducedLocations.length <= 0) {
        reducedLocations.push(currentLocation)
      } else {
        const lastLocation = reducedLocations[reducedLocations.length - 1]
        const lastDate = moment(`${lastLocation.date} ${lastLocation.time}`)
        const differenceInSeconds = moment.duration(currentDate.diff(lastDate)).asSeconds()
        if (differenceInSeconds >= 30) {
          reducedLocations.push(currentLocation)
        }
      }
    }

    // TODO: REMOVE FOR REAL DATA
    reducedLocations = [
      // Batch One
      { latitude: 38.0750120, longitude: -78.4911120, date: "08/05/2020", time: "08:15:00"},
      { latitude: 38.0750230, longitude: -78.4911230, date: "08/05/2020", time: "08:15:30" },
      { latitude: 38.0750670, longitude: -78.4911670, date: "08/05/2020", time: "08:17:30" },
      // Batch Two
      { latitude: 40.0750450, longitude: -78.5911450, date: "08/05/2020", time: "08:17:45" },
      { latitude: 40.0750560, longitude: -78.5911560, date: "08/05/2020", time: "08:25:45" },
      { latitude: 40.0750670, longitude: -78.5911670, date: "08/05/2020", time: "10:30:00" },
      // Batch Three
      { latitude: 33.0750450, longitude: -74.4911450, date: "08/05/2020", time: "10:45:00" },
      { latitude: 33.0750670, longitude: -74.4911670, date: "08/05/2020", time: "10:50:00" },
      // Batch Four
      { latitude: 38.5750450, longitude: -78.6911450, date: "08/05/2020", time: "12:15:00" },
      { latitude: 38.5750560, longitude: -78.6911560, date: "08/05/2020", time: "12:30:00" },
      // Batch Five
      { latitude: 40.5750560, longitude: -75.6911560, date: "08/05/2020", time: "15:15:00" },
      // Batch Six
      { latitude: 47.0750000, longitude: -67.4910000, date: "08/05/2020", time: "18:15:00" },
      { latitude: 47.0750000, longitude: -67.4910000, date: "08/05/2020", time: "18:30:00" },
    ]

    if (reducedLocations.length <= 0) { 
      this.setState({locationData: []})
    }

    // Batch locations into 25m chunks
    let locationBatches = []
    let currentBatch = {}
    for (let index = 0; index < reducedLocations.length; index++) {
      const currentLocation = reducedLocations[index]
      // If it's our first item. Start the first batch.
      if (index <= 0) {
        currentBatch.start = currentLocation
        currentBatch.end = currentLocation
      } else {
        // Calculate the distance between two coordinates in meters.
        const startCoord = [currentBatch.start.longitude, currentBatch.start.latitude]
        const endCoord = [currentLocation.longitude, currentLocation.latitude]
        const distanceInMeters = distance(startCoord, endCoord) * 1000
        // If the currentLocation is more than 10 meters from the start of the batch
        // end the batch and start a new one.
        if (distanceInMeters >= 25) {
          locationBatches.push(currentBatch)
          currentBatch = { start: currentLocation }
        }
        currentBatch.end = currentLocation
      }
    }

    // Required to handle the trailing batch.
    locationBatches.push(currentBatch)

    // Add time between the start and end dates.
    for (let index = 0; index < locationBatches.length; index++) {
      const batch = locationBatches[index]
      const startDate = moment(`${batch.start.date} ${batch.start.time}`)
      const endDate = moment(`${batch.end.date} ${batch.end.time}`)
      const duration = moment.duration(startDate.diff(endDate))
      locationBatches[index].duration = duration
    }

    this.setState({locationData: locationBatches, loadingLocationData: false})
  }

  // MARK: Filter Drivers

  filteredDrivers = (input, option) => {
    const driver = JSON.parse(option.value)
    const text = input.toLowerCase()
    const fullName = `${driver.driverfirstname} ${driver.driverlastname}`.toLowerCase()
    return fullName.includes(text) || driver.driverid.includes(text)
  }

  // MARK: Driver & Date Selection

  driverSelected = (driverString) => {
    let updatedState = {}
    // If the driver was cleared or the driver was changed... Reset
    // the location data so that the user can re-fetch again.
    if (driverString == null || this.state.selectedDriver !== null) {
      updatedState.locationData = null
    }

    updatedState.selectedDriver = (driverString == null) ? null : JSON.parse(driverString)
    this.setState(updatedState)
  }

  dateSelected = (dates, dateStrings) => {
    let updatedState = { selectedDates: dates }
    // If the date was cleared or the date was changed... Reset
    // the location data so that the user can re-fetch again.
    if (dates == null || this.state.selectedDates !== null) {
      updatedState.locationData = null
    }

    this.setState(updatedState)
  }

  // MARK: Render

  renderDriverOption = (driver, index) => {
    let fullName = `${driver.driverfirstname} ${driver.driverlastname}`
    if (!fullName.replace(/\s/g, '').length) {
      fullName = 'NO NAME'
    }

    return (
      <Select.Option key={`${index}`} value={JSON.stringify(driver)}>
        <div style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
          <Tag color='blue'>{driver.driverid}</Tag>
          <p className='historical-driver-option'>{fullName}</p>
        </div>
      </Select.Option>
    )
  }

  renderTimeline = () => {
    const { locationData } = this.state
    if (locationData.length < 0) { return <p>NO DATA</p> }

    return (
      <Timeline mode='right' style={{width: '100%'}}>
        {locationData.map((locationBatch, index) => {
          return (
            <Timeline.Item 
              key={`${index}`}
              label={locationBatch.duration.humanize()}
            >
              {locationBatch.start.time}
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }

  render = () => {
    // const { drivers = [] } = this.props
    // const { selectedDriver, selectedDates } = this.state
    // const fetchButtonDisabled = (selectedDriver == null || selectedDates == null)
    
    return <Redirect to={`/`}/>
    // return (
    //   <>
    //     <div id='live-header-container' style={{paddingLeft: SafeArea.left(24)}}>
    //       <Divider>Driver History</Divider>
    //       <Select
    //         allowClear
    //         onChange={this.driverSelected}
    //         style={{width: '100%'}}
    //         showSearch
    //         placeholder="Select a driver..."
    //         filterOption={this.filteredDrivers}
    //       >
    //         {drivers.map(this.renderDriverOption)}
    //       </Select>
    //       <DatePicker.RangePicker 
    //         style={{width: '100%', marginTop: 16}}
    //         format='YYYY/MM/DD'
    //         onChange={this.dateSelected}
    //       />
    //       <Button 
    //         type='primary' 
    //         loading={this.state.loadingLocationData}
    //         onClick={this.fetchLocationData}
    //         disabled={fetchButtonDisabled}
    //         style={{width: '100%', marginTop: 16}}
    //       >
    //         Fetch History
    //       </Button>
    //     </div>
    //     <div style={{margin: 20}}>
    //       {this.state.locationData ? this.renderTimeline() :  <p>NOT SET</p>}
    //     </div>
    //   </>
    // )
  }
}