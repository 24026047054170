// MARK: Imports

import React from 'react'
import { Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import Amplitude from './Amplitude'

// MARK: Constants

const TIMEOUT = 20000
const TIMEOUT_ERROR_MESSAGE = 'The request timed out! Check the company ID and your internet connection.'

const BASE_URL = 'https://swiftdispatch-server-<company_id>.frostbyteapps.com/api'

const TOKEN_STORAGE_KEY = 'swiftfleet@authorization_token'
const COMPANY_ID_STORAGE_KEY = 'swiftfleet@company_id'
const DISPATCHER_ID_STORAGE_KEY = 'swiftfleet@dispatcher_id'

const RECENT_LOCATION_IN_MINUTES = 5
const STALE_LOCATION_IN_MINUTES = 15

// MARK: Component

class Networking {

  // MARK: Constructor

  constructor() {
    this.baseURL = ''
    this.companyID = ''
    this.dispatcherID = ''
    this.clientVersion = '4'
    this.authorizationToken = ''
    this.authListeners = []
  }

  // MARK: Configure

  configure = (companyID) => {
    this.companyID = companyID
    this.baseURL = BASE_URL.replace('<company_id>', `${companyID}`)
  }

  restoreFromStorage = () => {
    const token = window.localStorage.getItem(TOKEN_STORAGE_KEY) || window.sessionStorage.getItem(TOKEN_STORAGE_KEY) || ''
    const companyID = window.localStorage.getItem(COMPANY_ID_STORAGE_KEY) || window.sessionStorage.getItem(COMPANY_ID_STORAGE_KEY) || ''
    const dispatcherID = window.localStorage.getItem(DISPATCHER_ID_STORAGE_KEY) || window.sessionStorage.getItem(DISPATCHER_ID_STORAGE_KEY) || ''

    this.configure(companyID)
    this.dispatcherID = dispatcherID
    this.authorizationToken = token
    this.notifyAuthenticationStatusChanged()
  }

  // MARK: Authentication Listeners

  subscribeToAuthenticationStatus = (listener) => {
    this.authListeners.push(listener)
  }

  unsubscribeFromAuthenticationStatus = (listener) => {
    this.authListeners = this.authListeners.filter((item) => {
      return listener !== item
    })
  }

  notifyAuthenticationStatusChanged = () => {
    const isAuthenticated = this.isAuthenticated()
    this.authListeners.forEach((listener) => {
      listener(isAuthenticated)
    })
  }
  
  // MARK: Helpers

  isAuthenticated = () => {
    return (this.companyID !== '' && this.dispatcherID !== '' && this.authorizationToken !== '')
  }

  // MARK: Login

  login = async (companyID, username, password, remember) => {
    this.configure(companyID)
    const url = this.baseURL + '/dispatchers/login'
    const method = 'POST'
    const body = {username, password}
    const response = await this.request(url, method, body)
    const key = response.key

    // If we logged in with a user successfully then we should clear what was there before, regardless
    // of the remember option.
    window.localStorage.removeItem(TOKEN_STORAGE_KEY)
    window.localStorage.removeItem(COMPANY_ID_STORAGE_KEY)
    window.localStorage.removeItem(DISPATCHER_ID_STORAGE_KEY)
    window.sessionStorage.removeItem(TOKEN_STORAGE_KEY)
    window.sessionStorage.removeItem(COMPANY_ID_STORAGE_KEY)
    window.sessionStorage.removeItem(DISPATCHER_ID_STORAGE_KEY)

    if (remember) {
      window.localStorage.setItem(TOKEN_STORAGE_KEY, key)
      window.localStorage.setItem(COMPANY_ID_STORAGE_KEY, companyID)
      window.localStorage.setItem(DISPATCHER_ID_STORAGE_KEY, username)
    } else {
      window.sessionStorage.setItem(TOKEN_STORAGE_KEY, key)
      window.sessionStorage.setItem(COMPANY_ID_STORAGE_KEY, companyID)
      window.sessionStorage.setItem(DISPATCHER_ID_STORAGE_KEY, username)
    }

    this.dispatcherID = username
    this.authorizationToken = key
    Amplitude.trackLoginCompleted(username, companyID, remember)
    this.notifyAuthenticationStatusChanged()
  }

  // MARK: Logout

  logout = async (code = null) => {
    this.authorizationToken = ''
    window.localStorage.removeItem(TOKEN_STORAGE_KEY)
    window.sessionStorage.removeItem(TOKEN_STORAGE_KEY)
    Amplitude.trackLogoutCompleted(code)
    Amplitude.clear()
    this.notifyAuthenticationStatusChanged()
  }

  // MARK: Company

  getCompanyInfo = async () => {
    const url = this.baseURL + '/company'
    const method = 'GET'
    const response = await this.request(url, method)
    Amplitude.configure(this.dispatcherID, response)
    return response
  }

  // MARK: Drivers

  getDriversInfo = async () => {
    const url = this.baseURL + '/drivers'
    const method = 'GET'
    const response = await this.request(url, method)
    return response
  }

  getLiveLocations = async (driverIDs) => {
    let url = this.baseURL + '/locations/live'
    const method = 'POST'
    const body = driverIDs

    let response = await this.request(url, method, body)
    // const now = new Date()
    const now = moment(new Date())
    Object.keys(response).forEach((driverID) => {
      let location = response[driverID]
      // Determine the status of the location. Is it recent, stale or expired?
      const timestamp = moment(new Date(`${location.date} ${location.time}`))
      const duration = moment.duration(timestamp.diff(now))
      const minutesAgo = Math.abs(duration.asMinutes())
      if (minutesAgo < RECENT_LOCATION_IN_MINUTES) {
        location.status = 'recent'
        location.statusCode = 3
      } else if (minutesAgo < STALE_LOCATION_IN_MINUTES) {
        location.status = 'stale'
        location.statusCode = 2
      } else {
        location.status = 'expired'
        location.statusCode = 1
      }

      location.description = `Last seen ${duration.humanize(true)}`
    })
    return response
  }

  getHistoricalLocations = async (driverIDs, startDate, startTime, endDate, endTime) => {
    let url = this.baseURL + '/locations/historical'
    const method = 'POST'
    const body = {
      start_date: startDate,
      start_time: startTime,
      end_date: endDate,
      end_time: endTime,
      drivers: driverIDs
    }

    const response = await this.request(url, method, body)
    return response
  }

  // MARK: Generic Request Helpers

  request = async (url, method, body = undefined, timeout = TIMEOUT) => {
    const response = await this.timeout(timeout, fetch(url, {
      method: method,
      headers: {
        'X-DISPATCHER-ID': this.dispatcherID,
        'X-CLIENT-VERSION': this.clientVersion,
        'X-AUTHORIZATION-TOKEN': this.authorizationToken,
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : null
    }))

    let json = null
    try {
      json = await response.json()
    } catch (error) {
      throw(new Error('Unable to parse the server\'s response.'))
    }

    this.verifyResponse(json)
    return json.payload
  }

  timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error(TIMEOUT_ERROR_MESSAGE)), ms)
      promise.then(resolve, reject)
    })
  }

  verifyResponse = (json) => {
    if (json.status !== 'SUCCESS') {
      let error = new Error(json.message)
      error.code = json.code
      if (json.code.startsWith('3') && this.authorizationToken) {
        this.logout(json.code)
        Modal.info({
          closable: false,
          centered: true,
          title: 'Oops!',
          icon: <CloseCircleOutlined style={{color: '#FF4D4F'}}/>,
          content: (
            <>
              <p>You have been logged out due to one of the following reasons...</p>
              <p style={{marginBottom: 4}}>• A lack of permissions</p>
              <p style={{marginBottom: 4}}>• Logging in on another device</p>
              <p style={{marginBottom: 4}}>• SwiftFleet is disabled for your company</p>
            </>
          ),
          okText: 'Okay',
        })
      } else {
        throw(error)
      }
    }
  }
}


export default new Networking()