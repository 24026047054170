
// MARK: Imports

import React from 'react'
import { Divider, Input, Radio, Button, message, Badge, Popover } from 'antd'
import { SearchOutlined, GatewayOutlined, SettingOutlined, StarFilled } from '@ant-design/icons'
import { presetPrimaryColors } from '@ant-design/colors'

import LiveSettings from  './components/LiveSettings'
import DriversList from '../DriversList'

import Amplitude from '../../../../../../utilities/Amplitude'
import { UserPreferencesContext } from '../../../../../../utilities/Contexts'
import Networking from '../../../../../../utilities/Networking'
import SafeArea from '../../../../../../utilities/SafeArea'

import Constants from '../../../../../../constants'

// MARK: Component

export default class Live extends React.Component {
  static contextType = UserPreferencesContext
  listContainerRef = React.createRef()

  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      listHeight: 0,
      listFilterSearchQuery: '',
      listFilterOption: 'all',
      driversLocations: {},
      driversMapVisibility: {}
    }
  }

  // MARK: Lifecycle

  componentDidMount = () => {
    Amplitude.trackPageView('DASHBOARD DRAWER LIVE')
    this.driversListDidChange() // This is here to trigger a render when switching between tabs.
    this.fetchLocations()
    this.windowDidResize()
    window.addEventListener('resize', this.windowDidResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.windowDidResize)
  }

  componentDidUpdate = (previousProps) => {
    // If the drivers have changes, update map and table to reflect.
    if (JSON.stringify(this.props.drivers) !== JSON.stringify(previousProps.drivers)) {
      this.driversListDidChange()
    }
  }

  windowDidResize = () => {
    let newState = {}
    if (this.listContainerRef.current !== null) {
      newState.listHeight = this.listContainerRef.current.clientHeight
    }

    this.setState(newState)
  }

  // MARK: Fetch

  fetchLocations = async () => {
    if (this.props.drivers && this.props.drivers.length > 0) {    
      try {
        const driverIDs = this.props.drivers.map((driver) => driver.driverid)
        const driversLocations = await Networking.getLiveLocations(driverIDs)
        this.setState({driversLocations}, this.updateMapDrivers)
      } catch (error) {
        Amplitude.trackError(error.message, 'DASHBOARD DRAWER LIVE DRIVERS FETCH', true)
        message.error('Failed to refresh locations.')
      }
    }
  }

  // MARK: Helpers

  // Called to update the driversMapVisibility state to be in sync with any potentially new drivers.
  driversListDidChange = async () => {
    if (this.props.drivers === undefined) { return }
    // Generate new drivers map visibility array based off old one and new drivers.
    let driversMapVisibility = {}
    this.props.drivers.forEach((driver) => {
      driversMapVisibility[driver.driverid] = this.state.driversMapVisibility[driver.driverid] || true
    })
    // Update state with new drivers. Upon completion, update the map to be in sync.
    this.setState({driversMapVisibility}, this.updateMapDrivers)
  }

  // MARK: Actions
  
  onDriverVisibilityClick = (driver, focusMode = null) => {
    let driversMapVisibility = {...this.state.driversMapVisibility}
    driversMapVisibility[driver.driverid] = !driversMapVisibility[driver.driverid]
    this.setState({driversMapVisibility}, () => {
      this.updateMapDrivers(focusMode)
    })   
  }

  onDriverClick = (driver) => {
    if (!this.state.driversLocations[driver.driverid]) { return }
    const focusMode = {type: 'driver', id: driver.driverid}
    if (!this.state.driversMapVisibility[driver.driverid]) {
      this.onDriverVisibilityClick(driver, focusMode)
    } else {
      this.props.updateMapFocus(focusMode)
    }
  }

  onFocusAllClick = () => {
    this.props.updateMapFocus({type: 'all'})
  }

  // MARK: Map Updates

  updateMapDrivers = (focusMode = null) => {
    const drivers = this.listFilteredDrivers().reduce((drivers, driver) => {
      const isVisible = this.state.driversMapVisibility[driver.driverid]
      const location = this.state.driversLocations[driver.driverid]
      if (isVisible && location != null) {
        drivers[driver.driverid] = {
          ...this.state.driversLocations[driver.driverid],
          name: driver.driverfirstname + ' ' + driver.driverlastname,
          availableforwork: driver.availableforwork,
          cellphone: driver.cellphone
        }
      }
      
      return drivers
    }, {})

    this.props.updateMapDrivers(drivers, focusMode)
  }

  // MARK: Drawer Driver List Search & Filter

  listFilterDidChange = (event) => {
    this.setState({listFilterOption: event.target.value}, () => {
      Amplitude.trackLiveFilterChanged(event.target.value, this.props.drivers.length)
      this.updateMapDrivers()
    })
  }

  listSearchQueryDidChange = (event) => {
    this.setState({listFilterSearchQuery: event.target.value}, this.updateMapDrivers)
  }

  listFilteredDrivers = () => {
    const text = this.state.listFilterSearchQuery
    const option  = this.state.listFilterOption
    if (this.props.drivers === undefined) { return null }
    let listFilteredDrivers = [...this.props.drivers]

    // Filter based on favorites
    if (this.context.preferences.liveOnlyShowFavorites) {
      listFilteredDrivers = listFilteredDrivers.filter((driver) => {
        return this.context.preferences.liveFavoriteDrivers.hasOwnProperty(driver.driverid)
      })
    }
    
    // Filter based on status
    if (option !== 'all') {
      listFilteredDrivers = listFilteredDrivers.filter((driver) => {
        return driver.availableforwork ? option === 'available' : option === 'unavailable'
      })
    }

    // Filter based on search query
    if (text !== '') {
      listFilteredDrivers = listFilteredDrivers.filter((driver) => {
        const fullName = driver.driverfirstname + ' ' + driver.driverlastname
        if (fullName.toLowerCase().includes(text.toLowerCase())) {
          return true
        } else if (driver.driverid.toLowerCase().includes(text.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
    }

    // Sort by (Availability > Location > Location Status > Driver Name > Driver ID)
    listFilteredDrivers.sort((driverA, driverB) => {
      // Check availability status.
      if (driverA.availableforwork !== driverB.availableforwork) {
        return driverA.availableforwork ? -1 : 1
      } 

      // Check existing location.
      const locationA = this.state.driversLocations[driverA.driverid]
      const locationB = this.state.driversLocations[driverB.driverid]
      if ((locationA == null && locationB != null) || (locationA != null && locationB == null)) {
        return locationA != null ? -1 : 1
      } 
      
      // Check status of location.
      if (locationA && locationB && locationA.statusCode !== locationB.statusCode) {
        return locationA.statusCode > locationB.statusCode ? -1 : 1
      }

      // Check driver name.
      const driverAName = (driverA.driverfirstname + driverA.driverlastname).toLowerCase()
      const driverBname = (driverB.driverfirstname + driverB.driverlastname).toLowerCase()
      if (driverAName !== driverBname) {
        return driverAName < driverBname ? -1 : 1
      }

      // Check driver ID.
      if (driverA.driverid !== driverB.driverid) {
        return driverA.driverid < driverB.driverid ? -1 : 1
      }

      return 0
    })
    
    return listFilteredDrivers
  }

  // MARK: Render

  renderActionBarContainer = (containerID, badgeCount) => {
    return (
      <div id={containerID}>
        <Popover 
          placement='topLeft' 
          title={'Live Settings'} 
          content={
            <LiveSettings />
          } 
          trigger='click'
        >
          <Button type='primary' icon={<SettingOutlined />} />
        </Popover>
        <div style={{flex: 1, marginLeft: 8}}>
          <Badge id='live-badge' showZero count={badgeCount}>
            <Button style={{marginleft: 8}} onClick={this.onFocusAllClick} type='primary' block icon={<GatewayOutlined />}>Focus All</Button> 
          </Badge>
        </div>
      </div>
    )
  }

  render = () => {
    const listFilteredDrivers = this.listFilteredDrivers()
    const badgeCount = listFilteredDrivers ? listFilteredDrivers.length : 0
    const isCompactHeight = window.innerHeight < Constants.breakpoints.compact
    const listContainerStyle = isCompactHeight ? null : { height: this.state.listHeight, overflowY: 'scroll' }
    return (
      <>
        <div id='live-header-container' style={{paddingLeft: SafeArea.left(24)}}>
          <Divider>
            {this.context.preferences.liveOnlyShowFavorites ? (
              <>
                <StarFilled style={{color: presetPrimaryColors.gold, marginRight: 8}}/>
                Your Favorite Drivers
              </>
            ) : 'Drivers'}
          </Divider>
          {/* All / Available / Unavailable */}
          <Radio.Group onChange={this.listFilterDidChange} defaultValue={this.state.listFilterOption} size='small' buttonStyle='solid' id='live-filter-radio-group'>
            <Radio.Button className='live-filter-radio-item' value='all'>All</Radio.Button>
            <Radio.Button className='live-filter-radio-item' value='available'>Available</Radio.Button>
            <Radio.Button className='live-filter-radio-item' value='unavailable'>Unavailable</Radio.Button>
          </Radio.Group>
          {/* Search Input */}
          <Input size='middle'
            disabled={listFilteredDrivers === undefined}
            prefix={<SearchOutlined />} 
            placeholder='Search by name or ID...' 
            onChange={this.listSearchQueryDidChange}
            style={{marginRight: 8, flex: 1}}
            allowClear
          />
          {isCompactHeight ? this.renderActionBarContainer('live-action-bar-top', badgeCount) : null}
        </div>
        <div ref={this.listContainerRef} id='live-driver-list-container' style={listContainerStyle}>
          <DriversList
            drivers={listFilteredDrivers}
            driversLocations={this.state.driversLocations}
            driversMapVisibility={this.state.driversMapVisibility} 
            onDriverVisibilityClick={this.onDriverVisibilityClick}
            onDriverClick={this.onDriverClick}
          />
        </div>
        { isCompactHeight ? null : (
          <div id='live-action-bar-bottom-container' style={{paddingLeft: SafeArea.left(24)}}>        
            {this.renderActionBarContainer('live-action-bar-bottom', badgeCount)}
          </div>
        )}
      </>
    )
  }
}