
// MARK: Imports

import React from 'react'
import { Checkbox, Select } from 'antd'

import { UserPreferencesContext } from '../../../../../../../utilities/Contexts'

// MARK: Component

export default class LiveSettings extends React.Component {
  static contextType = UserPreferencesContext

  // MARK: Driver Popups

  forceDriverPopups = (event) => {
    this.context.updateLiveForceDriverPopups(event.target.checked)
  }

  updateLiveDriverPopupStyle = (style) => {
    this.context.updateLiveDriverPopupStyle(style)
  }

  updateLiveDriverListStyle = (style) => {
    this.context.updateLiveDriverListStyle(style)
  }

  updateLiveOnlyShowFavorites = (event) => {
    this.context.updateLiveOnlyShowFavorites(event.target.checked)
  }

  // MARK: Render

  render = () => {
    return (
      <div className='live-settings-container'>
        {/* List Style */}
        <div className='live-settings-select-container' style={{marginBottom: 16}}>
          <span className='live-settings-select-description'>List Style</span>
          <Select 
            value={this.context.preferences.liveDriverListStyle}
            style={{width: 175}}
            onChange={this.updateLiveDriverListStyle}
          >
            <Select.Option value='detailed'>Detailed</Select.Option>
            <Select.Option value='normal'>Standard</Select.Option>
            <Select.Option value='compact'>Compact</Select.Option>
          </Select>
        </div>
        {/* Popup Style */}
        <div className='live-settings-select-container' style={{marginBottom: 16}}>
          <span className='live-settings-select-description'>Popup Style</span>
          <Select 
            value={this.context.preferences.liveDriverPopupStyle}
            style={{width: 175}}
            onChange={this.updateLiveDriverPopupStyle}
          >
            <Select.Option value='name'>Name</Select.Option>
            <Select.Option value='driver_id'>Driver ID</Select.Option>
            <Select.Option value='name_and_driver_id'>Name & Driver ID</Select.Option>
          </Select>
        </div>
        {/* Force Popups */}
        <div style={{marginBottom: 16}}>
          <Checkbox 
            onChange={this.forceDriverPopups} 
            checked={this.context.preferences.liveForceDriverPopups}
          >
            Always Show Driver Popups
          </Checkbox>
        </div>
        {/* Favorites Toggle */}
        <div>
          <Checkbox 
            onChange={this.updateLiveOnlyShowFavorites} 
            checked={this.context.preferences.liveOnlyShowFavorites}
          >
            Only Show Your Favorites
          </Checkbox>
        </div>
      </div>
    )
  }
}