
// MARK: Imports

import React from 'react'
import { List } from 'antd'

import DriversListItem from './components/DriverListItem'

import { UserPreferencesContext } from '../../../../../../utilities/Contexts'

// MARK: Constants

const SkeletonCount = 3

// MARK: Component

export default class DriversList extends React.Component {
  static contextType = UserPreferencesContext

  // MARK: Skeleton Data

  createSkeletonDataSource = () => {
    let dataSource = []
    for (let i = 0; i < SkeletonCount; i++) {
      dataSource.push({driverid: `${i}`, skeleton: true})
    }

    return dataSource
  }

  // MARK: Update Favorites

  onDriverFavoriteClick = (driverID) => {
    const liveFavoriteDrivers = {...this.context.preferences.liveFavoriteDrivers}
    if (liveFavoriteDrivers.hasOwnProperty(driverID)) {
      delete liveFavoriteDrivers[driverID]
    } else {
      liveFavoriteDrivers[driverID] = true
    }

    this.context.updateLiveFavoriteDrivers(liveFavoriteDrivers)
  }

  // MARK: Render

  renderItem = (driver) => {
    let favorite = driver.skeleton ? null : this.context.preferences.liveFavoriteDrivers.hasOwnProperty(driver.driverid)
    let visible = driver.skeleton ? null : this.props.driversMapVisibility[driver.driverid]
    let location = driver.skeleton ? null : this.props.driversLocations[driver.driverid]
    return (
      <DriversListItem 
        driver={driver}
        location={location}
        onClick={this.props.onDriverClick}
        favorite={favorite}
        visible={visible} 
        onVisibilityClick={this.props.onDriverVisibilityClick}
        onFavoriteClick={this.onDriverFavoriteClick}
      />
    )
  }

  render = () => {
    const { drivers } = this.props
    return (
      <List
        split={this.props.drivers !== undefined}
        itemLayout='horizontal'
        dataSource={drivers || this.createSkeletonDataSource()}
        renderItem={this.renderItem}
      />
    )
  }
}