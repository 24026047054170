
// MARK: Constants

const LIVE_DRIVER_POPUP_STYLE_KEY = 'swiftfleet@live_driver_popup_style'
const LIVE_DRIVER_LIST_STYLE_KEY = 'swiftfleet@live_driver_list_style'
const LIVE_FORCE_DRIVER_POPUPS_KEY = 'swiftfleet@live_force_driver_popups'
const LIVE_FAVORITE_DRIVERS_KEY = 'swiftfleet@live_favorite_drivers'
const LIVE_ONLY_SHOW_FAVORITES_KEY = 'swiftfleet@live_only_show_favorites'

// MARK: Component

class StoredPreferences {

  // MARK: Constructor

  constructor() {
    const liveDriverPopupStyle = window.localStorage.getItem(LIVE_DRIVER_POPUP_STYLE_KEY)
    const liveDriverListStyle = window.localStorage.getItem(LIVE_DRIVER_LIST_STYLE_KEY)
    const liveForceDriverPopups = window.localStorage.getItem(LIVE_FORCE_DRIVER_POPUPS_KEY)
    const liveFavoriteDrivers = window.localStorage.getItem(LIVE_FAVORITE_DRIVERS_KEY)
    const liveOnlyShowFavorites = window.localStorage.getItem(LIVE_ONLY_SHOW_FAVORITES_KEY)

    this.state = {
      liveDriverPopupStyle: liveDriverPopupStyle || 'name_and_driver_id',
      liveDriverListStyle: liveDriverListStyle || 'detailed',
      liveForceDriverPopups: liveForceDriverPopups === 'true',
      liveFavoriteDrivers: JSON.parse(liveFavoriteDrivers) || {},
      liveOnlyShowFavorites: liveOnlyShowFavorites === 'true'
    }
  }

  // MARK: Stored Preferences

  preferences = () => {
    return this.state
  }

  // MARK: Clear

  clear = () => {
    window.localStorage.removeItem(LIVE_DRIVER_POPUP_STYLE_KEY)
    window.localStorage.removeItem(LIVE_DRIVER_LIST_STYLE_KEY)
    window.localStorage.removeItem(LIVE_FORCE_DRIVER_POPUPS_KEY)
    window.localStorage.removeItem(LIVE_FAVORITE_DRIVERS_KEY)
    window.localStorage.removeItem(LIVE_ONLY_SHOW_FAVORITES_KEY)
  }

  // MARK: Update

  updateLiveDriverPopupStyle = (liveDriverPopupStyle) => {
    this.state.liveDriverPopupStyle = liveDriverPopupStyle
    window.localStorage.setItem(LIVE_DRIVER_POPUP_STYLE_KEY, liveDriverPopupStyle)
  }

  updateLiveDriverListStyle = (liveDriverListStyle) => {
    this.state.liveDriverListStyle = liveDriverListStyle
    window.localStorage.setItem(LIVE_DRIVER_LIST_STYLE_KEY, liveDriverListStyle)
  }

  updateLiveForceDriverPopups = (liveForceDriverPopups) => {
    this.state.liveForceDriverPopups = liveForceDriverPopups
    window.localStorage.setItem(LIVE_FORCE_DRIVER_POPUPS_KEY, `${liveForceDriverPopups}`)
  }

  updateLiveFavoriteDrivers = (liveFavoriteDrivers) => {
    this.state.liveFavoriteDrivers = liveFavoriteDrivers
    window.localStorage.setItem(LIVE_FAVORITE_DRIVERS_KEY, JSON.stringify(liveFavoriteDrivers))
  }

  updateLiveOnlyShowFavorites = (liveOnlyShowFavorites) => {
    this.state.liveOnlyShowFavorites = liveOnlyShowFavorites
    window.localStorage.setItem(LIVE_ONLY_SHOW_FAVORITES_KEY, `${liveOnlyShowFavorites}`)
  }
}


export default new StoredPreferences()