
class SafeArea {

  top = (fallback) => {
    const inset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sat"))
    return inset === 0 ? fallback : inset
  }

  bottom = (fallback) => {
    const inset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sab"))
    return inset === 0 ? fallback : inset
  }

  left = (fallback) => {
    const inset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sal"))
    return inset === 0 ? fallback : inset
  }

  right = (fallback) => {
    const inset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sar"))
    return inset === 0 ? fallback : inset
  }
}

export default new SafeArea()