
// MARK: Imports

import React from 'react'

// MARK: Contexts

export const CompanyContext = React.createContext(null)

export const UserPreferencesContext = React.createContext({
  preferences: {
    liveDriverPopupStyle: 'name_and_driver_id',
    liveDriverListStyle: 'detailed',
    liveForceDriverPopups: false,
    liveFavoriteDrivers: [],
    liveOnlyShowFavorites: false
  },
  updateLiveDriverPopupStyle: () => {},
  updateLiveDriverListStyle: () => {},
  updateLiveForceDriverPopups: () => {},
  updateLiveFavoriteDrivers: () => {},
  updateLiveOnlyShowFavorites: () => {}
})