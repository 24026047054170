
// MARK: Imports

import React from 'react'
import { Tag } from 'antd'

// MARK: Component

export default class DriverPopup extends React.Component {

  renderName = () => {
    const { driver } = this.props
    return <span className='map-popup-driver-name' style={{marginTop: 0}}>{driver.name}</span>
  }

  renderDriverID = () => {
    const { id } = this.props
    return <Tag color='blue' style={{marginRight: 0}}>{id}</Tag>
  }

  renderNameAndDriverID = () => {
    const { id, driver } = this.props
    return (
      <>
        <Tag color='blue'>{id}</Tag>
        <span className='map-popup-driver-name' style={{marginTop: 0}}>{driver.name}</span>
      </>
    )
  }

  render = () => {
    let component = null
    switch (this.props.style) {
      case 'name':
        component = this.renderName()
        break
      case 'driver_id':
        component = this.renderDriverID()
        break
      case 'name_and_driver_id':
        component = this.renderNameAndDriverID()
        break
      default:
        component = this.renderNameAndDriverID()
        break
    }

    return (
      <div className='map-popup-container'>
        {component}
      </div>
    )
  }
}