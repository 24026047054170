
// MARK: Imports

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Spin, message } from 'antd'

import Amplitude from './utilities/Amplitude'
import Networking from './utilities/Networking'

import Login from './screens/Login'
import Dashboard from './screens/Dashboard'

import Constants from './constants'
import Logo from './assets/logo.png'

// MARK: Configuration

message.config({
  top: 128,
})

// MARK: Component

export default class App extends React.Component {
  
  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      renderMode: 'standard',
      authenticated: null
    }
  }

  // MARK: Lifecycle

  componentDidMount() {
    this.windowDidResize()
    window.addEventListener('resize', this.windowDidResize)
    Networking.subscribeToAuthenticationStatus(this.authenticationStatusDidChange)
    Networking.restoreFromStorage()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize)
    Networking.unsubscribeFromAuthenticationStatus(this.authenticationStatusDidChange)
  }

  // MARK: Window Resize

  windowDidResize = () => {
    this.setState({height: document.documentElement.clientHeight})
    const width = window.innerWidth
    let newRenderMode
    if (width < Constants.breakpoints.compact) {
      newRenderMode = 'compact'
    } else if (width < Constants.breakpoints.standard) {
      newRenderMode = 'standard'
    } else {
      newRenderMode = 'wide'
    }

    if (newRenderMode !== this.state.renderMode) {
      this.setState({renderMode: newRenderMode}, () => {
        Amplitude.updateRenderMode(newRenderMode)
      })
    }
  }

  // MARK: Authentication Listener

  authenticationStatusDidChange = async (authenticated) => {
    this.setState({authenticated})
  }

  // MARK: Render

  renderLoadingPage = () => {
    return (
      <div id='loading-container' style={{height: this.state.height}}>
        <img alt='SwiftFleet Logo' src={Logo} id='loading-image' />
        <Spin size='large' />
      </div>
    )
  }

  render = () => {
    const authenticated = this.state.authenticated
    if (authenticated === null) {
      return this.renderLoadingPage()
    } else {
      return (
        <Router>
          <Switch>
            <Route path='/login'>
              {authenticated ? <Redirect to='/dashboard/live' /> : <Login />}
            </Route>
            <Route path='/dashboard'>            
              {authenticated ? <Dashboard renderMode={this.state.renderMode} /> : <Redirect to='/login' />}
            </Route>
            <Route>
              <Redirect to='/login' />
            </Route>
          </Switch>
        </Router>
      )
    }
  }
}