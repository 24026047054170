
// MARK: Imports

import React from 'react'
import { Layout, Button, Popover } from 'antd'
import { MenuFoldOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';

import { CompanyContext } from '../../../utilities/Contexts'
import Amplitude from '../../../utilities/Amplitude'
import Networking from '../../../utilities/Networking'
import SafeArea from '../../../utilities/SafeArea'

import Logo from '../../../assets/logo.png'

// MARK: Component

export default class Header extends React.Component {
  static contextType = CompanyContext

  // MARK: Render

  renderCompactHeader = (icon, companyName) => {
    return (
      <Layout.Header id='header' style={{paddingLeft: SafeArea.left(24), paddingRight: SafeArea.right(24)}}>
        <Button onClick={this.props.toggleDrawer} type='primary' icon={icon} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <img alt='SwiftFleet Logo' src={Logo} id='header-logo'/>
        </div>
        <Popover 
          placement='bottomRight' 
          title={Networking.dispatcherID} 
          content={<a href='#/' onClick={Networking.logout}>Sign Out</a>} 
          trigger='click'
        >
          <Button type='primary' icon={<UserOutlined />} />
        </Popover>
      </Layout.Header>
    )
  }

  renderStandardHeader = (icon, companyName) => {
    return (
      <Layout.Header id='header' style={{paddingLeft: SafeArea.left(24), paddingRight: SafeArea.right(24)}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button onClick={this.props.toggleDrawer} type='primary' icon={icon} />
          <span id='header-customer-name' className='no-select'>{companyName}</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>        
          <img alt='SwiftFleet Logo' src={Logo} id='header-logo'/>
          <span id='header-application-name' className='no-select'>SWIFTFLEET</span>
          <Popover 
            placement='bottomRight' 
            title={Networking.dispatcherID} 
            content={
              <a href='#/' onClick={() => {
                Amplitude.trackLogoutStarted()
                Networking.logout()
              }}>
                Sign Out
              </a>
            } 
            trigger='click'
          >
            <Button type='primary' icon={<UserOutlined />} />
          </Popover>
        </div>
      </Layout.Header>
    )
  }

  renderWideHeader = (icon, companyName) => {
    return (
      <Layout.Header id='header' style={{paddingLeft: SafeArea.left(24), paddingRight: SafeArea.right(24)}}>
        {/* Left Widgets */}
        <div className='header-container' style={{justifyContent: 'flex-start', flex: 1}}>
          <Button onClick={this.props.toggleDrawer} size='large' type='primary' icon={icon}/>
          <span id='header-customer-name' className='no-select'>{companyName}</span>
        </div>
        {/* Center Widgets */}
        <div className='header-container'>
          <img alt='SwiftFleet Logo' src={Logo} id='header-logo'/>
          <span id='header-application-name' className='no-select'>SWIFTFLEET</span>
        </div>
        {/* Right Widgets */}
        <div className='header-container' style={{justifyContent: 'flex-end', flex: 1}}>
          <Popover 
            placement='bottomRight' 
            title={Networking.dispatcherID} 
            content={<a href='#/' onClick={Networking.logout}>Sign Out</a>} 
            trigger='click'
          >
            <Button type='primary' size='large' icon={<UserOutlined />} />
          </Popover>
        </div>
      </Layout.Header>
    )
  }
  
  render = () => {
    const companyName = this.context.company || ''
    const icon = this.props.drawerVisible ? <MenuFoldOutlined /> : <MenuOutlined />
    switch (this.props.renderMode) {
      case 'wide':
        return this.renderWideHeader(icon, companyName)
      case 'standard':
        return this.renderStandardHeader(icon, companyName)
      case 'compact':
        return this.renderCompactHeader(icon, companyName)
      default:
        return null
    }
  }
}