// MARK: Imports

// import amplitude from 'amplitude-js'

// MARK: Component

class Amplitude {

  // MARK: Constructor

  // constructor() {
    // amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_TOKEN)
    // this.amplitude = amplitude.getInstance()
  // }

  // MARK: Configure

  configure = (username, company) => {
    // const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
    // const properties = {
    //   environment: isDevelopment,
    //   username: username                                        || null,
    //   company_name: company.company                             || null,
    //   company_id: company.companyid                             || null,
    //   location_history_setting: company.locationhistoryage      || null,
    //   swiftdispatch_enabled: company.swiftdispatchenabled       || null,
    //   swiftdispatch_expiration: company.swiftdispatchexpiration || null,
    //   swiftfleet_enabled: company.swiftfleetenabled             || null,
    //   swiftfleet_expiration: company.swiftfleetexpiration       || null
    // }

    // this.amplitude.setUserProperties(properties)
  }

  updateRenderMode = (renderMode) => {
    // var identify = new amplitude.Identify().set('render_mode', renderMode)
    // this.amplitude.identify(identify)
  }

  clear = () => {
    // this.configure(null, {})
  }

  // MARK: Login

  trackLoginStarted = (username, companyID, remember) => {
    // const properties = {
    //   username: username,
    //   company_id: companyID,
    //   remember: remember
    // }

    // this.amplitude.logEvent('LOGIN STARTED', properties)
  }

  trackLoginCompleted = (username, companyID, remember) => {
    // const properties = {
    //   username: username,
    //   company_id: companyID,
    //   remember: remember
    // }

    // this.amplitude.logEvent('LOGIN COMPLETED', properties)
  }

  trackLoginFailure = (username, companyID, remember, error) => {
    // const properties = {
    //   username: username,
    //   company_id: companyID,
    //   remember: remember,
    //   error: error
    // }

    // this.amplitude.logEvent('LOGIN FAILED', properties)
  }

  // MARK: Logout

  trackLogoutStarted = (code = null) => {
    // const properties = {
    //   code: code
    // }

    // this.amplitude.logEvent('LOGOUT STARTED', properties)
  }

  trackLogoutCompleted = (code = null) => {
    // const properties = {
    //   code: code
    // }

    // this.amplitude.logEvent('LOGOUT COMPLETED', properties)
  }

  trackLogoutError = (code = null, error) => {
    // const properties = {
    //   code: code,
    //   error: error
    // }

    // this.amplitude.logEvent('LOGOUT ERROR', properties)
  }

  // MARK: Page View

  trackPageView = (page) => {
    // const properties = {
    //   page: page
    // }

    // this.amplitude.logEvent('PAGE VIEWED', properties)
  }

  // MARK: Dashboard

  trackDrawerToggled = (isOpen) => {
    // const properties = {
    //   is_open: isOpen
    // }

    // this.amplitude.logEvent('DASHBOARD DRAWER TOGGLED', properties)
  }

  // MARK: Live

  trackLiveFilterChanged = (filter, driversCount) => {
    // const properties = {
    //   filter: filter,
    //   number_of_drivers: driversCount
    // }

    // this.amplitude.logEvent('LIVE FILTER CHANGED', properties)
  }

  // MARK: Map

  trackMapForcePopupSettingChanged = (forcePopups, featuresCount) => {
    // const properties = {
    //   force_driver_popups: forcePopups,
    //   number_of_features: featuresCount
    // }

    // this.amplitude.logEvent('MAP SETTING CHANGED', properties)
  }

  trackMapPopupStyleSettingChanged = (popupStyle, featuresCount) => {
    // const properties = {
    //   driver_popup_style: popupStyle,
    //   number_of_features: featuresCount
    // }

    // this.amplitude.logEvent('MAP SETTING CHANGED', properties)
  }

  // MARK: Error

  trackError = (error, location, displayed) => {
    // const properties = {
    //   error: error,
    //   location: location,
    //   displayed: displayed
    // }

    // this.amplitude.logEvent('ERROR', properties)
  }
}


export default new Amplitude()