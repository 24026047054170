
// MARK: Imports

import React from 'react'
import { Card, Form, Input, Checkbox, Button, Alert } from 'antd'

import Amplitude from '../utilities/Amplitude'
import Networking from '../utilities/Networking'

import Logo from '../assets/logo.png'

// MARK: Component

export default class Login extends React.Component {

  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      loading: false,
      errorMessage: null
    }
  }

  // MARK: Lifecycle

  componentDidMount() {
    this.windowDidResize()
    Amplitude.trackPageView('LOGIN')
    window.addEventListener('resize', this.windowDidResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.windowDidResize)
  }

  // MARK: Window Resize

  windowDidResize = () => {
    this.setState({height: document.documentElement.clientHeight})
  }

  // Sign In

  signIn = (values) => {
    this.setState({loading: true}, async () => {
      const { companyID, username, password, remember } = values
      Amplitude.trackLoginStarted(username, companyID, remember)
      let state = { loading: false }
      try {
        await Networking.login(companyID, username, password, remember)
      } catch (error) {
        state.errorMessage = error.message
        Amplitude.trackLoginFailure(username, companyID, remember, error.message)
        this.setState(state)
      }
    })
  }

  // MARK: Render

  render = () => {
    return (
      <div id='login-background' style={{height: this.state.height}}>
        <Card id='login-card'>
          <div id='login-card-header-container'>
            <img alt='SwiftFleet Logo' src={Logo} id='login-logo' />
            <span id='login-application-name'>SwiftFleet</span>
            {this.state.errorMessage ? <Alert style={{width: '100%', textAlign: 'center', marginBottom: 16}} showIcon message={this.state.errorMessage} type='error' /> : null}
          </div>
          <Form
            name='login'
            size='middle'
            initialValues={{ remember: false }}
            onFinish={this.signIn}
          >
            <Form.Item
              initialValue={Networking.companyID || ''}
              name='companyID'
              rules={[{ required: true, message: 'Please input your company id!' }]}
            >
              <Input placeholder='Company ID'/>
            </Form.Item>

            <Form.Item
              initialValue={Networking.dispatcherID || ''}
              name='username'
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder='Username' />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password  placeholder='Password' visibilityToggle={false}/>
            </Form.Item>

            <Form.Item 
              name='remember' 
              valuePropName='checked'
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button loading={this.state.loading} type='primary' htmlType='submit' block>Sign In</Button>
            </Form.Item>
          </Form>
          <span style={{textAlign: 'center', display: 'block', fontSize: '0.85em'}}>Frostbyte Applications LLC</span>
        </Card>
      </div>
    )
  }
}