
// MARK: Imports

import React from 'react'
import { presetPrimaryColors } from '@ant-design/colors'

// MARK: Component

export default class PulsingDot extends React.Component {

  // MARK: Helpers

  statusColor = (driver) => {
    let color = presetPrimaryColors.grey
    if (driver.availableforwork) {
      switch(driver.status) {
        case 'recent':
          color = presetPrimaryColors.green
          break
        case 'stale':
          color = presetPrimaryColors.orange
          break
        case 'expired':
          color = presetPrimaryColors.red
          break
        default:
          break
      }
    }

    return color
  }

  // MARK: Render

  renderPulsingRing = (driver, color) => {
    if (driver.availableforwork && driver.status !== 'expired') {
      return <div className='map-pulsating-circle-ring' style={{backgroundColor: color}} />
    } else {
      return null
    }
  }

  render = () => {
    const { driver } = this.props
    const color = this.statusColor(driver)
    return (
      <div style={{cursor: 'pointer'}}>
        <div className='map-pulsating-circle-container'>        
          {this.renderPulsingRing(driver, color)}
          <div className='map-pulsating-circle-dot' style={{backgroundColor: color}} />  
        </div>
      </div>
    )
  }
}