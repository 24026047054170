
// MARK: Imports

import React from 'react'
import ReactDOM from 'react-dom'
import mapboxgl from 'mapbox-gl'
import { Space, Layout, Button } from 'antd'

import Amplitude from '../../../../utilities/Amplitude'

import DriverPopup from './components/DriverPopup'
import PulsingDot from './components/PulsingDot'

// MARK: Constants

const POPUP_OPTIONS = {
  closeButton: false,
  closeOnClick: false,
  offset: 10,
  anchor: 'bottom'
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

// MARK: Map

export default class Map extends React.Component {

  mapRef = React.createRef()
    
  // MARK: Constructor
  
  constructor(props) {
    super(props)
    this.state = {
      windowHeight: 0,
      windowWidth: 0,
      features: {},
      focusMode: {type: 'all'},
    }
  }

  // MARK: Lifecycle

  componentDidMount = () => {
    this.map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/streets-v9',
      container: this.mapRef.current,
      attributionControl: false,
      pitchWithRotate: false,
      center: [-98.35, 39.50],
      maxZoom: 20,
    })

    this.map.addControl(new mapboxgl.NavigationControl({
      showCompass: false,
      showZoom: true,
      visualizePitch: false
    }))

    this.map.addControl(new mapboxgl.FullscreenControl({
      container: document.querySelector('body')
    }))

    // Disable map rotation using right click + drag.
    this.map.dragRotate.disable()
    
    // Disable map rotation using touch rotation gesture.
    this.map.touchZoomRotate.disableRotation()

    // Disable pitching using touchPitch
    this.map.touchPitch.disable()

    // Disable map interaction using the keyboard.
    this.map.keyboard.disable()

    // Disable map box zoom interaction
    this.map.boxZoom.disable()

    this.map.on('wheel', () => {
      this.updateFocus({type: 'free'})
    })

    this.map.on('touchstart', () => {
      this.updateFocus({type: 'free'})
    })

    this.map.on('mousedown', () => {
      this.updateFocus({type: 'free'})
    })

    this.updateLiveForceDriverPopups(this.props.liveForceDriverPopups)
  }

  componentDidUpdate(previousProps) {
    if (this.props.drawerVisible !== previousProps.drawerVisible) {
      this.updateFocus(this.state.focusMode)
    } 

    if (this.props.liveForceDriverPopups !== previousProps.liveForceDriverPopups) {
      this.updateLiveForceDriverPopups(this.props.liveForceDriverPopups)
    }

    if (this.props.liveDriverPopupStyle !== previousProps.liveDriverPopupStyle) {
      this.updateLiveDriverPopupStyle(this.props.liveDriverPopupStyle)
    }
  }

  // MARK: Padding Helper

  additionalLeftPadding = () => {
    return this.props.drawerVisible ? this.props.drawerWidth : 0
  }

  // MARK: Update Helpers

  updateDrivers = (drivers, focusMode) => {
    let updatedFocusMode = focusMode || this.state.focusMode
    let features = {...this.state.features}

    // If any of the features are missing from the new drivers list, cleanup.
    Object.keys(features).forEach((featureKey) => {
      if (!drivers.hasOwnProperty(featureKey)) {
        const { marker, markerContainer, popup, popupContainer } = features[featureKey]
        ReactDOM.unmountComponentAtNode(popupContainer)
        ReactDOM.unmountComponentAtNode(markerContainer)
        popup.remove()
        marker.remove()
        delete features[featureKey]

        // If the focusMode is focused on the removed driver, fallback to all.
        if (updatedFocusMode.type === 'driver' && updatedFocusMode.id === featureKey) {
          updatedFocusMode = {type: 'all'}
        }
      }
    })

    Object.keys(drivers).forEach((driverKey) => {
      const driver = drivers[driverKey]

      // If we have an existing marker.
      if (features.hasOwnProperty(driverKey)) {
        const { marker, markerContainer, popupContainer } = features[driverKey]
        marker.setLngLat([driver.longitude, driver.latitude])
        ReactDOM.render(<PulsingDot driver={driver} id={driverKey} />, markerContainer)
        ReactDOM.render(<DriverPopup driver={driver} id={driverKey} style={this.props.liveDriverPopupStyle} />, popupContainer)

      } else {
        // Setup Marker
        const markerContainer = document.createElement('div')
        ReactDOM.render(<PulsingDot driver={driver} id={driverKey} />, markerContainer)
        const marker = new mapboxgl.Marker(markerContainer)
        marker.setLngLat([driver.longitude, driver.latitude])
        marker.addTo(this.map)

        markerContainer.addEventListener('click', (e) => { 
          this.updateFocus({type: 'driver', id: driverKey})
          e.stopPropagation()
        })
        
        // Setup Popup
        const popupContainer = document.createElement('div')
        ReactDOM.render(<DriverPopup driver={driver} id={driverKey} style={this.props.liveDriverPopupStyle} />, popupContainer)
        const popup = new mapboxgl.Popup(POPUP_OPTIONS).setDOMContent(popupContainer)
        marker.setPopup(popup)

        if (this.props.liveForceDriverPopups) {
          marker.togglePopup()
        }

        // Add listener to show the popup on mouseenter.
        markerContainer.addEventListener('mouseenter', (event) => {
          if (!popup.isOpen()) {
            marker.togglePopup()
          }
        })

        // Add listener to hide the popup on mouseleave.
        markerContainer.addEventListener('mouseleave', (event) => {
          if (popup.isOpen() && !this.props.liveForceDriverPopups) {
            marker.togglePopup()
          }
        })

        features[driverKey] = { marker, markerContainer, popup, popupContainer, driver }
      }
    })

    this.setState({features, focusMode: updatedFocusMode}, () => {
      this.updateFocus(updatedFocusMode)
    })
  }

  updateFocus = (focusMode) => {
    if (focusMode.type === 'free') {
      this.setState({focusMode: {type: 'free'}})
    } else if (focusMode.type === 'all') {
      this.focusAllDrivers()
    } else if (focusMode.type === 'driver') {
      this.focusDriver(focusMode.id)
    }
  }

  updateLiveForceDriverPopups = (liveForceDriverPopups) => {
    const featureKeys = Object.keys(this.state.features)
    featureKeys.forEach((featureKey) => {
      const marker = this.state.features[featureKey].marker
      const popup = marker.getPopup()
      if (popup === null) { return }
      if ((!popup.isOpen() && liveForceDriverPopups) || (popup.isOpen() && !liveForceDriverPopups)) {
        marker.togglePopup()
      }
    })

    Amplitude.trackMapForcePopupSettingChanged(liveForceDriverPopups, featureKeys.length)
  }

  updateLiveDriverPopupStyle = (style) => {
    const featureKeys = Object.keys(this.state.features)
    featureKeys.forEach((featureKey) => {
      const { popupContainer, driver } = this.state.features[featureKey]
      ReactDOM.render(<DriverPopup driver={driver} id={featureKey} style={this.props.liveDriverPopupStyle} />, popupContainer)
    })

    Amplitude.trackMapPopupStyleSettingChanged(style, featureKeys.length)
  }

  // MARK: Focus Helpers

  focusDriver = (id) => {
    const feature = this.state.features[id]
    if (feature) {
      const coords = feature.marker.getLngLat()
      this.map.fitBounds([coords, coords], {
        maxZoom: 20,
        padding: {top: 100, bottom: 100, left: 100 + this.additionalLeftPadding(), right: 100}
      })
      this.setState({focusMode: {type: 'driver', id}})
    } else {
      this.focusAllDrivers()
    }
  }

  focusAllDrivers = () => {
    const featureKeys = Object.keys(this.state.features)
    if (featureKeys.length <= 0) { return }
    
    let maxLat; let minLat; let maxLong; let minLong
    featureKeys.forEach((featureKey) => {
      const { lat, lng } = this.state.features[featureKey].marker.getLngLat()
      maxLat = Math.max(lat, maxLat || lat)
      minLat = Math.min(lat, minLat || lat)
      maxLong = Math.max(lng, maxLong || lng)
      minLong = Math.min(lng, minLong || lng)
    })

    
    this.map.fitBounds([[minLong, minLat], [maxLong, maxLat]], {
      maxZoom: 20,
      padding: {top: 100, bottom: 100, left: 100 + this.additionalLeftPadding(), right: 100}
    })

    this.setState({focusMode: {type: 'all'}})
  }

  // MARK: Render

  renderFocusLabel = () => {
    const { focusMode } = this.state
    let text = 'Freelooking'
    if (focusMode.type === 'all') {
      text = 'Focusing All Visible Drivers'
    } else if (focusMode.type === 'driver') {
      text = 'Focusing ' + this.state.features[focusMode.id].driver.name
    }

    return (
      <div id='map-focus-indicator-container' style={{marginLeft: this.additionalLeftPadding()}}>
        <Button style={{pointerEvents: 'none'}} type='primary'>{text}</Button>
      </div>
    )
  }
  
  render = () => {
    return (  
      <Layout.Content style={{position: 'relative'}}>
        <div id='map' ref={this.mapRef}>
          {this.renderFocusLabel()}
          <div id='map-attribution-container'>
            <Space id='map-attribution' className='no-select'>
              <a className='map-attribution-link' rel='noopener noreferrer' target='_blank' href='https://www.mapbox.com/about/maps/'>© Mapbox</a> 
              <a className='map-attribution-link' rel='noopener noreferrer' target='_blank' href='http://www.openstreetmap.org/about/'>© OpenStreetMap</a>
              <a className='map-attribution-link' rel='noopener noreferrer' target='_blank' href='https://www.mapbox.com/map-feedback/'>Improve this map</a>
            </Space>
          </div>
        </div>
      </Layout.Content>
    )
  }
}