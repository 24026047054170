
// MARK: Imports

import React from 'react'
import { List, Tag, Button, Skeleton, message } from 'antd'
import { presetPrimaryColors } from '@ant-design/colors'
import { EyeOutlined, EyeInvisibleOutlined, StarOutlined, StarFilled } from '@ant-design/icons'


import { UserPreferencesContext } from '../../../../../../../utilities/Contexts'
import SafeArea from '../../../../../../../utilities/SafeArea'

// MARK: Component

export default class DriversListItem extends React.Component {
  static contextType = UserPreferencesContext

  // MARK: Actions

  onVisibilityClick = (event) => {
    event.stopPropagation()
    if (this.props.onVisibilityClick) {
      this.props.onVisibilityClick(this.props.driver)
    }
  }

  onFavoriteClick = (event) => {
    event.stopPropagation()
    if (this.props.onFavoriteClick) {
      this.props.onFavoriteClick(this.props.driver.driverid)
      if (this.props.favorite) {
        message.info(`${this.props.driver.driverfirstname} was removed from your favorites!`)
      } else {
        message.success(`${this.props.driver.driverfirstname} has been added to your favorites!`)
      }
    }
  }

  onClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.driver)
    }
  }

  // MARK: Render

  renderStatus = (driver, location) => {
    let color = presetPrimaryColors.grey
    if (driver.availableforwork) {
      const updated = (location || {}).status || 'expired'
      switch(updated) {
        case 'recent':
          color = presetPrimaryColors.green
          break
        case 'stale':
          color = presetPrimaryColors.orange
          break
        case 'expired':
          color = presetPrimaryColors.red
          break
        default:
          break
      }
    }

    return <div className='driver-list-item-status' style={{backgroundColor: color}} />
  }

  renderListItem = () => {
    const { driver, visible, location, favorite } = this.props

    let name = driver.driverfirstname + ' ' + driver.driverlastname
    if (!name.replace(/\s/g, '').length) {
      name = 'NO NAME'
    }

    const listStyle = this.context.preferences.liveDriverListStyle
    const description = location ? location.description : 'No location'
    return (
      <>
        {this.renderStatus(driver, location)}
        <div className='driver-list-item-info-container'>
          {/* Name and ID should be adjacent in compact style */}
          <div style={listStyle === 'compact' ? {flexDirection: 'row', display: 'flex'} : null}>
            <Tag color='blue'>{driver.driverid}</Tag>
            <p className='driver-list-item-name'>{name}</p>
          </div>
          {/* Hide location in compact style */}
          {listStyle !== 'compact' ? (
            <p className='driver-list-item-timestamp'>{description}</p>
          ) : null}
          {/* Phone Number only shows in detailed style */}
          {listStyle === 'detailed' && driver.cellphone ? (
            <p className='driver-list-item-phone'>{driver.cellphone}</p>
           ) : null}
        </div>
        {location ? (
          <Button 
            size='middle' 
            onClick={this.onVisibilityClick} 
            type='link' 
            icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined style={{color: presetPrimaryColors.red}} />}
          />
        ) : null }
        {this.context.preferences.liveOnlyShowFavorites ? null : (
          <Button
            size='middle'
            onClick={this.onFavoriteClick} 
            type='link' 
            icon={favorite ? <StarFilled style={{color: presetPrimaryColors.gold}}/> : <StarOutlined style={{color: presetPrimaryColors.grey}} />}
          />
        )}
      </>
    )
  }

  render = () => {
    const { driver } = this.props
    return (
      <List.Item onClick={this.onClick} className='driver-list-item' style={{paddingLeft: SafeArea.left(16)}}>
        <Skeleton loading={driver.skeleton} paragraph={null} active avatar>
          {driver.skeleton ? null : this.renderListItem()}
        </Skeleton>
      </List.Item>
    )
  }
}